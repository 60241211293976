import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import Link from 'gatsby-link'
import Img from 'gatsby-image'
//import PageTransition from 'gatsby-plugin-page-transitions';

import SEO from '../../../components/SEO'
//import Hero from '../../../components/hero';
import ArticlePreview from '../../../components/article-preview'
import social_image from '../../../assets/images/social-image.png'
import icon32 from '../../../assets/images/favicon-32x32.png'
import Template from '../../../components/layout'

class RootIndex extends React.Component {
  render() {
    const siteTitle =
      'Nutrition Assessment - Blog by a Nutritionist Psychotherapist'
    const posts = get(this, 'props.data.allContentfulBlogPost.edges')
    // const [author] = get(this, 'props.data.allContentfulPerson.edges');

    return (
      <Template location={get(this, 'props.location')}>
        <div
          style={{
            background: 'none repeat scroll 0 0 rgba(255, 255, 255, 0.7)',
          }}
        >
          <Helmet
            title={siteTitle}
            link={[
              { rel: 'shortcut icon', type: 'image/png', href: `${icon32}` },
              {
                rel: 'alternate',
                href: 'https://www.ilsevalle.com/blog',
                hreflang: 'es',
              },
              {
                rel: 'alternate',
                href: 'https://www.ilsevalle.com/en/nutrition-blog',
                hreflang: 'x-default',
              },
            ]}
          >
            <html lang="en" />
          </Helmet>
          <SEO
            seodesc={
              'Ilse Valle-Jones&#039;s blog, a bilingual professional nutritionist psychotherapist. Lose weight, exercise, and enjoy a healthy life style'
            }
            socialImage={social_image}
            socialTitle={
              'Nutrition Assessment - Blog by a Nutritionist Psychotherapist'
            }
            twitter={'@ilsevallejones'}
          />
          <div className="wrapper">
            <Img
              sizes={
                this.props.data.breakfast.edges[0].node.childImageSharp.fluid
              }
              style={{
                maxHeight: '400px',
                height: '61.8vh',
                marginBottom: '1em',
              }}
              loading="eager"
              fadeIn={false}
            />
            <div className="container">
              <div className="row">
                <div className="span7 offset1">
                  {posts.map(({ node }) => {
                    return (
                      <article
                        key={node.slug}
                        className="entry-header"
                        style={{ paddingBottom: '3em' }}
                      >
                        <ArticlePreview article={node} />
                      </article>
                    )
                  })}
                </div>
                <div className="span4">
                  <div className="widget-area">
                    <aside id="text-4" className="widget widget_text">
                      <h3 className="widget-title">Ilse Valle-Jones, MS</h3>{' '}
                      <div className="textwidget">
                        <p>Nutritionist Psychotherapist</p>
                        <Link to="/#contact" style={{ color: '#bc360a' }}>
                          Contact Me
                        </Link>
                      </div>
                    </aside>
                    <aside id="text-3" className="widget widget_text">
                      {' '}
                      <div className="textwidget">
                        I am a bilingual professional nutritionist
                        psychotherapist. Here you will find some resources to
                        develop well-being and enjoy a healthy lifestyle.
                      </div>
                    </aside>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Template>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query HomeQuery {
    allContentfulBlogPost(
      sort: { fields: [publishDate], order: DESC }
      filter: { lang: { eq: "en" } }
    ) {
      edges {
        node {
          title
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          tags
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    allContentfulPerson(filter: { id: { eq: "c15jwOBqpxqSAOy2eOO4S0m" } }) {
      edges {
        node {
          name
          shortBio {
            shortBio
          }
          title
        }
      }
    }
    breakfast: allFile(filter: { relativePath: { regex: "/breakfast.jpg/" } }) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 1200, quality: 85) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`
